<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0 px-3">
              <div
                class="d-flex align-items-center justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <div class="upload-logo">
                      <img id="logo-img" :src="entreprise_logo" alt="" />
                    </div>
                  </div>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2 pr-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h2 class="font-weight-bold">
                      {{
                        query === "invoice" || query === "eInvoice" ? "Facture" : "Devis"
                      }}
                    </h2>
                  </div>
                </div>
              </div>
            </b-card-body>

            <b-row class="container mx-auto px-3">
              <b-col cols="12">
                <b-form-group>
                  <h5 class="font-weight-bold">
                    <span class="font-weight-bold">
                      {{
                        query === "invoice" || query === "eInvoice"
                          ? "Objet de la facture"
                          : "Objet du devis"
                      }}
                    </span>
                  </h5>
                  <b-form-input
                    v-model="invoiceData.libelle"
                    placeholder="Objet : création de site web"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="container mx-auto px-3">
              <b-col cols="12" xl="6">
                <b-form-group>
                  <h5 class="font-weight-bold">
                    Date d'émission <span class="text-danger">*</span>
                  </h5>
                  <flat-pickr
                    @input="validateDate"
                    v-model="dateDefault"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" xl="6">
                <b-form-group>
                  <h5 class="font-weight-bold">
                    Date d'échéance <span class="text-danger">*</span>
                  </h5>
                  <flat-pickr
                    @input="validateDate"
                    v-model="dueDate"
                    class="form-control"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div
              v-if="date_alert"
              class="d-flex align-items-center justify-content-center"
            >
              <p class="text-danger">{{ date_alert }}</p>
            </div>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="mb-lg-1">
                  <h6 class="mb-2 text-uppercase font-weight-bold">
                    {{ query === "invoice" ? "Facture" : "Devis" }} à
                    <span class="text-danger">*</span> :
                  </h6>

                  <!-- Select Client -->
                  <v-select
                    v-model="factureClient"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="clientList"
                    label="nom"
                    input-id="invoice-data-client"
                    placeholder="Choisir un client"
                    empty-label="Aucun client"
                    error-messages="Aucun client"
                    emp
                  >
                    <template #label>
                      Choix du client <span class="text-danger">*</span>
                    </template>
                    <template #list-header>
                      <li
                        v-b-modal.add-new-client
                        class="add-new-client-header d-flex align-items-center my-50"
                      >
                        <feather-icon icon="PlusIcon" size="16" />
                        <span class="align-middle ml-25">Ajouter un nouveau client</span>
                      </li>
                    </template>
                    <template v-slot:option="option">
                      {{ option.nom }} {{ option.prenoms }}
                    </template>
                  </v-select>

                  <!-- Get Client -->
                  <div v-if="factureClient" class="mt-2">
                    <h6 class="mb-2 text-uppercase font-weight-bold">Client :</h6>
                    <h6 class="mb-25">
                      Nom : {{ factureClient.nom }} {{ factureClient.prenoms }}
                    </h6>
                    <b-card-text class="mb-25">
                      Adresse :
                      {{
                        factureClient.localisation
                          ? factureClient.localisation.formatted_address
                          : "No defined"
                      }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      Contact : {{ factureClient.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      Email : {{ factureClient.email }}
                    </b-card-text>
                  </div>
                </b-col>

                <!-- Select Entreprise
                 
                 -
                 -
                 --
                 -
                  -->

                <b-col>
                  <h6 class="mb-2 text-uppercase font-weight-bold">
                    {{ query === "invoice" ? "De l'entrepise" : "De l'entrepise" }}
                    <span class="text-danger">*</span> :
                  </h6>
                  <b-form-input
                    disabled
                    v-model="entrerpiseData.libelle"
                    placeholder="Objet : création de site web"
                  ></b-form-input>

                  <!-- Get Entrepise -->
                  <div class="mt-2">
                    <h6 class="mb-2 text-uppercase font-weight-bold">Entreprise :</h6>
                    <h6 class="mb-25">Nom : {{ entrerpiseData.libelle }}</h6>
                    <b-card-text class="mb-25">
                      Adresse :
                      {{
                        entrerpiseData.localisation
                          ? entrerpiseData.localisation.formatted_address
                          : "No defined"
                      }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      Contact : {{ entrerpiseData.contact }}
                    </b-card-text>
                    <b-card-text class="mb-0">
                      Email : {{ entrerpiseData.email }}
                    </b-card-text>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Items Section -->
            <b-card-body class="invoice-padding form-item-section">
              <div ref="form" class="repeater-form" :style="{ height: trHeight }">
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="bg-light p-1 mt-1"
                  style="border-radius: 10px"
                >
                  <!-- Item Form -->
                  <!-- ? This will be in loop => So consider below markup for single item -->
                  <b-col cols="12">
                    <div class="mb-1 d-flex justify-content-between border-bottom p-50">
                      <span>Detail du service ou produit #{{ index + 1 }}</span>
                      <div
                        class="bg-white p-0 d-flex align-items-center justify-content-center cursor-pointer"
                        style="border-radius: 100%; width: 25px; height: 25px"
                      >
                        <feather-icon icon="XIcon" @click="removeItem(index)" />
                      </div>
                    </div>
                    <!-- Form Input Fields OR content inside bordered area  -->
                    <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                    <div class="d-flex flex-column">
                      <b-row class="flex-grow-1 pt-1">
                        <!-- Single Item Form Headers -->
                        <b-col cols="12" lg="6">
                          <label class="">Article</label>
                          <v-select
                            v-model="item.itemTitle"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="articlesList"
                            input-id="invoice-data-article"
                            label="libelle"
                            :clearable="false"
                            class="mb-1 item-selector-title"
                            placeholder="Selectionner un article"
                            @input="(val) => updateItemForm(index, val)"
                            empty-label="Aucun article"
                          >
                            <template #list-header>
                              <li
                                v-b-modal.e-add-new-article
                                class="add-new-client-header d-flex align-items-center my-50"
                              >
                                <feather-icon icon="PlusIcon" size="16" />
                                <span class="align-middle ml-25">Nouvel article</span>
                              </li>
                            </template>
                          </v-select>
                        </b-col>
                        <b-col cols="12" lg="6">
                          <label class="">Cout</label>
                          <b-form-input
                            v-model="item.cout"
                            type="number"
                            class="mb-1"
                            min="0"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" lg="6">
                          <label class="">Qté</label>
                          <b-form-input
                            min="1"
                            v-model="item.qte"
                            type="number"
                            class="mb-2"
                          />
                        </b-col>
                        <b-col cols="12" lg="6">
                          <label class="">Prix</label>
                          <p class="mb-1">
                            {{ formatter((item.prix = item.cout * item.qte)) }}
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>

            <b-card-body>
              <b-row>
                <b-col cols="8">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="md"
                    variant="primary"
                    @click="addNewItemInItemForm"
                  >
                    <feather-icon icon="PlusIcon" size="16" />
                    Ajouter un article <span class="text-ligth">*</span>
                  </b-button>
                </b-col>

                <b-col cols="4">
                  <b-button
                    id="form-item-settings-icon"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    <div class="cursor-pointer">Remise / Taxe</div>

                    <!-- Setting Item Form -->
                    <b-popover
                      ref="form-item-settings-popover"
                      target="form-item-settings-icon"
                      triggers="click"
                      placement="lefttop"
                    >
                      <b-form @submit.prevent>
                        <b-row>
                          <!-- Field: Remise -->
                          <b-col cols="12">
                            <b-form-group
                              label="Remise (%)"
                              label-for="setting-item-discount"
                            >
                              <b-form-input
                                id="setting-item-discount"
                                value="null"
                                type="number"
                                v-model="invoiceRemise"
                              />
                            </b-form-group>
                          </b-col>

                          <!-- Field: Tax 1 -->
                          <b-col cols="12">
                            <b-form-group label="Taxe" label-for="setting-item-tax-1">
                              <v-select
                                v-model="invoiceTaxe"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="libelle"
                                :options="taxesList"
                                input-id="setting-item-tax-1"
                                empty-label="Aucune taxe"
                              >
                                <template #option="option">
                                  {{ option.libelle }} - {{ option.valeur }}%
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>

                          <b-col cols="12" class="d-flex justify-content-between mt-1">
                            <b-button
                              variant="outline-secondary"
                              @click="
                                () => {
                                  $refs[`form-item-settings-popover`].$emit('close');
                                }
                              "
                            >
                              Annuler
                            </b-button>
                            <b-button
                              variant="outline-primary"
                              @click="ApplicationEl(invoiceTaxe, invoiceRemise)"
                            >
                              Appliquer
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-popover>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Sales Persion -->
                <b-col
                  cols="12"
                  md="4"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="8"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="d-flex flex-column invoice-total-wrapper">
                    <div class="invoice-total-item d-flex">
                      <p class="invoice-total-title">Sous total:</p>
                      <p class="invoice-total-amount">
                        {{
                          invoiceData.items.length !== 0
                            ? formatter(getSousTotal(invoiceData.items))
                            : formatter(0)
                        }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p v-if="invoiceData.remise" class="invoice-total-title">
                        Remise {{ invoiceData.remise }}% :
                      </p>
                      <p v-if="!invoiceData.remise" class="invoice-total-title">
                        Remise 0%:
                      </p>
                      <p class="invoice-total-amount">
                        {{
                          !invoiceData.remise
                            ? formatter(0)
                            : formatter(
                                parseInt(
                                  getSousTotal(invoiceData.items) -
                                    (getSousTotal(invoiceData.items) *
                                      invoiceData.remise) /
                                      100
                                )
                              )
                        }}
                      </p>
                    </div>
                    <div class="invoice-total-item">
                      <p v-if="invoiceData.taxe" class="invoice-total-title">
                        Taxe {{ invoiceData.taxe.valeur }}% :
                      </p>
                      <p v-if="!invoiceData.taxe" class="invoice-total-title">Taxe 0%:</p>
                      <p class="invoice-total-amount">
                        {{
                          !invoiceData.taxe
                            ? formatter(0)
                            : formatter(
                                getSousTotal(invoiceData.items) *
                                  (1 + invoiceData.taxe.valeur / 100)
                              )
                        }}
                      </p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <h5 class="invoice-total-title font-weight-bold">Total :</h5>
                      <h4 class="invoice-total-amount">
                        {{
                          invoiceData.items.length !== 0
                            ? formatter(sumArticles())
                            : formatter(0)
                        }}
                      </h4>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0">
              <span class="font-weight-bold">Note: </span>
              <b-form-textarea v-model="invoiceData.note" class="mt-1" />
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->

      <b-col
        cols="12"
        md="12"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
        style="position: relative; max-height: 100%"
      >
        <!-- Action Buttons -->
        <b-card class="" style="position: sticky !important; top: 110px">
          <!-- Button: Send Invoice -->
          <!-- <b-button
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						variant="primary"
						class="mb-75"
						block
						v-b-modal.modal-mail-sender
						@click="sendFactureEmail"
					>
						Envoyer
					</b-button> -->

          <!-- Button: DOwnload -->
          <!-- If page edit -->
          <b-button
            :disabled="QUOTES_PERMISSIONS_UPDATE ? false : true"
            v-if="query === 'eDevis'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="QUOTES_PERMISSIONS_UPDATE ? editInfoTreatments('devis') : ''"
          >
            Sauvegader le devis
          </b-button>

          <!-- If page edit -->
          <b-button
            :disabled="INVOICE_PERMISSIONS_UPDATE ? false : true"
            v-if="query === 'eInvoice'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="
              INVOICE_PERMISSIONS_UPDATE
                ? editInfoTreatments('facture')
                : qAccessForbidden()
            "
          >
            Sauvegader la facture
          </b-button>

          <b-button
            v-if="query === 'invoice'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="INVOICE_PERMISSIONS ? PreviewFacture('facture') : qAccessForbidden()"
          >
            Previsualiser
          </b-button>

          <!-- Button: DOwnload -->
          <!-- <b-button
						v-if="query === 'devis'"
						v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						variant="primary"
						class="mb-75"
						block
						@click="PreviewFacture('devis')"
					>
						Previsualiser et envoyer
					</b-button> -->

          <!-- Button: Print -->
          <b-button
            v-if="query === 'invoice'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="INVOICE_PERMISSIONS ? saveFacture('facture') : qAccessForbidden()"
          >
            Enregistrer
          </b-button>

          <!-- Button: Print -->
          <b-button
            :disabled="QUOTES_PERMISSIONS ? false : true"
            v-if="query === 'devis'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            block
            @click="saveFacture('devis')"
          >
            Enregistrer
          </b-button>
          <!-- Button: Print -->
          <b-button
            v-if="
              query === 'invoice' ||
              query === 'eInvoice' ||
              query === 'devis' ||
              query === 'eDevis'
            "
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :to="{ name: query === 'invoice' ? 'FactureList' : 'DevisList' }"
          >
            {{
              query === "invoice" || query === "eInvoice"
                ? "Liste des factures"
                : "Liste des devis"
            }}
          </b-button>

          <b-button
            v-if="
              query === 'invoice' ||
              query === 'eInvoice' ||
              query === 'devis' ||
              query === 'eDevis'
            "
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            :to="{
              name:
                query === 'invoice' || query === 'eInvoice' ? 'FactureList' : 'DevisList',
            }"
          >
            Annuler
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <!-- ====== AJOUTER UN NOUVEAU CLIENT ======== -->
    <!-- select 2 demo -->
    <e-add-new-client :clientList="clientList" />
    <q-invoice-mail-sender :invoiceData="invoiceData" v-if="mailSender === true" />

    <!-- ====== AJOUTER UN NOUVEL ARTICLE ======== -->
    <!-- modal login-->
    <e-add-articles :categories="categories" :articles="articlesList" />
  </section>
</template>

<script>
import {
  ref,
  onUnmounted,
  reactive,
  onBeforeMount,
  computed,
} from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  VBToggle,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";
import axios from "axios";
import URL from "@/views/pages/request";
import { VueTelInput } from "vue-tel-input";
import "animate.css";
import paymentRealTime from "@/utils/utilsInvoice/paymentRealTime";
import EAddArticles from "@/components/__partials/articles/eAddArticles.vue";
import EAddNewClient from "@/components/__partials/eAddNewClient.vue";
import QInvoiceMailSender from "@/components/invoiceDetails/mailSender/qInvoiceMailSender.vue";
import { toast_orign, toast_sucess, toast_update } from "@/utils/qToast";
import { qDecrypted } from "@/services/qeCrypt";
import qDeviseUtils from "@/utils/qDeviseUtils";
import moment from "moment";
import qDeleteAtUtils from "@/utils/qDeleteAtUtils";
import usePerm from "@/middleware/permissions";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BModal,
    flatPickr,
    vSelect,
    VBModal,
    VueTelInput,
    EAddArticles,
    EAddNewClient,
    QInvoiceMailSender,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-toggle": VBToggle,
  },
  name: "FactureList",
  mixins: [heightTransition],
  async mounted() {
    document.title = "Facture";
    this.initTrHeight();

    // if(this.$route.path === '/edit/devis'){
    //   const getData = JSON.parse(localStorage.getItem('edit_facture'));
    //     this.factureClient = getData.client;
    //     this.dateDefault = getData.date_emission;
    //     this.dueDate = getData.date_echeance;

    //     getData.taxe = getData.taxes.length === 0 ? null : getData.taxes;
    //     getData.articles_id = [];
    //     getData.articles.forEach((el, index) => {
    //       getData.articles_id.push(el.id);
    //     });
    //  this.invoiceData = getData
    // }

    this.AllGive();

    /*****
     * @Entreprise : List de information de l'entreprise
     */
    await axios
      .get(URL.ENTREPRISE_INFO)
      .then((res) => {
        this.entrerpiseData = res.data[0].entreprise;
        console.log(this.entrerpiseData);
        if (typeof this.entrerpiseData.localisation === "string") {
          this.entrerpiseData.localisation = JSON.parse(
            res.data[0].entreprise.localisation
          );
        }

        this.entreprise_logo = res.data[0].logo_entreprise;
      })
      .catch((error) => {
        console.log(error);
      });

    /*****
     * @Clients : List de information du clients
     */
    await axios
      .get(URL.CLIENT_ALL)
      .then((res) => {
        console.log(res.data);
        this.clientList = res.data.acheteur;

        this.clientList.reverse();

        this.clientList.forEach((client) => {
          if (client.localisation !== null) {
            if (typeof client.localisation === "string") {
              client.localisation = JSON.parse(client.localisation);
            } else {
              client.localisation = client.localisation;
            }
          } else {
            this.clientList = res.data[0];
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });

    /*****
     * @Articles : List de information du artices
     */
    this.getDataArticles();

    /*****
     * @Taxes : list des taxes
     */
    await axios
      .get(URL.TAXE_LIST)
      .then((res) => {
        this.taxesList = res.data.taxes;
        this.taxesList = qDeleteAtUtils.delete_update_at(this.taxesList);
        this.taxesList.reverse();
      })
      .catch((error) => {
        console.log(error);
      });

    /*****
     * @Category : list des category
     */
    await axios
      .get(URL.CATEGORIE_LIST)
      .then((response) => {
        this.categories = response.data;
        this.categories.reverse();
      })
      .catch((error) => {
        console.log(error);
      });
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    async getDataArticles() {
      await axios
        .get(URL.ARTICLE_LIST)
        .then((res) => {
          this.articlesList = res.data[0];
          this.articlesList = qDeleteAtUtils.delete_update_at(this.articlesList);
          this.articlesList.reverse();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    ApplicationEl(invoiceTaxe, invoiceRemise) {
      // console.log(invoiceTaxe, invoiceRemise);
      if (invoiceTaxe !== null) {
        this.invoiceData.taxe = invoiceTaxe;
        console.log(this.invoiceData.taxe);
      } else {
        this.invoiceData.taxe = null;
      }
      if (invoiceRemise !== null) {
        this.invoiceData.remise = invoiceRemise;
      } else {
        this.invoiceData.remise = 0;
      }
      this.$refs[`form-item-settings-popover`].$emit("close");
    },

    validateDate() {
      const date_creation = this.dateDefault;
      const date_fin = this.dueDate;
      if (date_creation > date_fin) {
        this.date_alert =
          "La date d'émission ne peut pas etre supérieur à la date d'écheance";
      } else {
        this.date_alert = null;
      }
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1);
      // this.invoiceData.totalTaxe.splice(index, 1)
      // this.invoiceData.taxes_id.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    // ***
    // FUNCTION TO UPLOAD LOGO
    // **
    chooseFile() {
      const input = document.querySelector("#input-file");
      input.click();
    },
    processFile(event) {
      this.choose = true;
      this.picture = event.target.files[0];
      if (event.target.files.length !== 0) {
        const picturePath = URL.createObjectURL(this.picture);
        const picture = document.querySelector("#logo-img");
        picture.src = picturePath;
      } else {
        const picture = document.querySelector("#logo-img");
        picture.src = "";
      }
    },
    deletePicture() {
      this.choose = false;
      this.picture = "";
      const picture = document.querySelector("#logo-img");
      picture.src = "";
    },
    // *****
    // ***
    // FUNCTION POUR AVOIR LE TOTAL DE TOUS LES ARTICLES AJOUTEES
    // ***
    sumArticles() {
      let price = "";

      for (let i = 0; i < this.invoiceData.items.length; i++) {
        price = this.invoiceData.items;
      }

      Array.prototype.sum = function (prop) {
        let total = 0;
        for (let i = 0, _len = this.length; i < _len; i++) {
          total += this[i][prop];
        }
        return parseFloat(total);
      };

      if (this.invoiceData.remise && this.invoiceData.taxe) {
        let totalWithRemise =
          price.sum("prix") - price.sum("prix") * (this.invoiceData.remise / 100);
        let num = parseFloat(totalWithRemise * (1 + this.invoiceData.taxe.valeur / 100));
        this.invoiceData.facturePrixTotal = num.toFixed(2);
        return num.toFixed(2);
      } else if (this.invoiceData.remise && !this.invoiceData.taxe) {
        let totalWithRemise =
          price.sum("prix") - price.sum("prix") * (this.invoiceData.remise / 100);
        let num = parseFloat(totalWithRemise);
        this.invoiceData.facturePrixTotal = num.toFixed(2);
        return num.toFixed(2);
      } else if (!this.invoiceData.remise && this.invoiceData.taxe) {
        let totalWithoutRemise = price.sum("prix");
        let num = parseFloat(
          totalWithoutRemise * (1 + this.invoiceData.taxe.valeur / 100)
        );
        this.invoiceData.facturePrixTotal = num.toFixed(2);
        return num.toFixed(2);
      } else {
        this.invoiceData.facturePrixTotal = price.sum("prix");
        return parseFloat(price.sum("prix"));
      }

      // return parseFloat(price.sum('prix'))
    },

    // ***
    // **
    // FAIRE LA SOMME DES PRODUITS SANS LES TAXES NI LA REMISE
    // **
    // ***
    getSousTotal(arr) {
      let price = "";

      for (let i = 0; i < arr.length; i++) {
        price = arr;
      }

      Array.prototype.sum = function (prop, top) {
        let total = 0;
        for (let i = 0, _len = this.length; i < _len; i++) {
          total += this[i][prop] * this[i][top];
        }
        return total;
      };

      this.invoiceData.facturePrixTotalHt = price.sum("cout", "qte");
      return price.sum("cout", "qte");
    },

    // Alerte quand facture enregistré avec succès
    confirmText(text, confirmButtonText, oklinks, cancelButtonText) {
      this.$swal({
        title: "Succès",
        text: text,
        icon: "success",
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.trTrimHeight(this.$refs.row[0].offsetHeight);
          this.factureClient = null;
          this.dateDefault = null;
          this.dueDate = null;
          this.invoiceData.client = null;
          this.invoiceData.items = [];
          this.invoiceData.taxes_id = [];
          this.invoiceData.articles_id = [];
          this.invoiceData.date_emission = null;
          this.invoiceData.dateDefault = null;
          this.invoiceData.nombre_article = 0;
          this.invoiceData.note = "Taper une note ici";
          this.invoiceData.facturePrixTotal = 0;
        } else {
          this.$router.push(oklinks);
        }
      });
    },
    // Alerte quand facture non enregistré
    errorText() {
      this.$swal({
        title: "Erreur",
        text: "oups, une erreur c'est produite, veillez rééssayer",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Rééssayer",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.saveFacture();
        } else {
          this.$router.push(this.$route.path);
        }
      });
    },

    // ***
    // **
    // FUNTION QUI MONTRE TOUTES LES INFOS RECEUILLI DANS LA FACTURE
    // **
    async saveFacture(type) {
      if (!this.dateDefault) {
        // shake alert quand le champ date d'émission n'est pas rempli
        this.$swal({
          title: "Ajouter une date d'émission",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.dueDate) {
        // shake alert quand le champ date d'échéance n'est pas rempli
        this.$swal({
          title: "Ajouter une date d'échéance",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.factureClient) {
        // shake alert quand le champ client n'est pas rempli
        this.$swal({
          title: "Ajouter un client",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.invoiceData.items[0]) {
        // shake alert quand le champ article n'est pas rempli
        this.$swal({
          title: "Ajouter un article",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.invoiceData.items[0].itemTitle) {
        // shake alert quand le champ article n'est pas rempli
        this.$swal({
          title: "Ajouter un article",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else {
        const config = {
          headers: {
            Accept: "application/json",
          },
        };

        this.sendModel(
          config,
          false,
          type,
          URL.FACTURE_CREATE,
          `${
            type === "devis"
              ? "Votre devis à été creer avec succéss"
              : "Votre facture à été creer avec succéss"
          }`,
          `${type === "devis" ? "Nouveau devis" : "Nouvelle facture"}`,
          `${type === "devis" ? "/lists/devis" : "/lists/factures"}`,
          `${type === "devis" ? "Liste des devis" : "Liste des factures"}`
        );
      }
    },

    // ***
    // **
    // FUNTION QUI MONTRE TOUTES LES INFOS RECEUILLI DANS LA FACTURE
    // **
    async editInfoTreatments(type) {
      if (!this.dateDefault) {
        // shake alert quand le champ date d'émission n'est pas rempli
        this.$swal({
          title: "Ajouter une date d'émission",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.dueDate) {
        // shake alert quand le champ date d'échéance n'est pas rempli
        this.$swal({
          title: "Ajouter une date d'échéance",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.factureClient) {
        // shake alert quand le champ client n'est pas rempli
        this.$swal({
          title: "Ajouter un client",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.invoiceData.items[0]) {
        // shake alert quand le champ article n'est pas rempli
        this.$swal({
          title: "Ajouter un article",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.invoiceData.items[0].itemTitle) {
        // shake alert quand le champ article n'est pas rempli
        this.$swal({
          title: "Ajouter un article",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else {
        const config = {
          headers: {
            Accept: "application/json",
          },
        };
        console.log(
          this.invoiceData.articles_id.filter(
            (art, idx) => idx < this.invoiceData.items.length
          )
        );

        const editData = {
          id: this.invoiceData.id,
          client_id: this.factureClient.id,
          entreprise_id: this.factureClient.id,
          client: this.factureClient.id,
          date_emission: this.dateDefault,
          date_echeance: this.dueDate,
          entreprise: this.factureClient.id,
          items: this.invoiceData.items,
          vendeur: this.invoiceData.vendeur,
          description: this.invoiceData.note,
          total_ttc: this.invoiceData.facturePrixTotal,
          total_ht: this.invoiceData.facturePrixTotalHt,
          total_taxe: this.invoiceData.taxe === null ? 0 : this.invoiceData.taxe.valeur,
          remise: this.invoiceData.remise,
          nombre_article: this.invoiceData.items.length,
          articles_id: this.invoiceData.articles_id.filter(
            (art, idx) => idx < this.invoiceData.items.length
          ),
          taxes_id:
            this.invoiceData.taxe === null
              ? this.invoiceData.taxe
              : this.invoiceData.taxe.id,
          type_facture: type,
          etat: type === "devis" ? "draft" : "validate",
          libelle: this.invoiceData.libelle,
          note: this.invoiceData.note,
        };

        console.log("data : ", editData);

        try {
          const { data } = await axios.post(URL.FACTURE_UPDATE, editData, config);
          toast_update(
            this,
            "success",
            "top-right",
            "La modification à été prise en compte !"
          );
          type === "facture"
            ? this.$router.push({ name: "FactureList" })
            : this.$router.push({ name: "DevisList" });
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      }
    },

    // ***
    // **
    // FUNTION POUR PREVISUALISER LA FACTURE
    // **
    async PreviewFacture(type) {
      const config = {
        headers: {
          Accept: "application/json",
        },
      };
      if (!this.dateDefault) {
        // shake alert quand le champ date d'émission n'est pas rempli
        this.$swal({
          title: "Ajouter une date d'émission",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.dueDate) {
        // shake alert quand le champ date d'échéance n'est pas rempli
        this.$swal({
          title: "Ajouter une date d'échéance",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.factureClient) {
        // shake alert quand le champ client n'est pas rempli
        this.$swal({
          title: "Ajouter un client",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.invoiceData.items[0]) {
        // shake alert quand le champ article n'est pas rempli
        this.$swal({
          title: "Ajouter un article",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else if (!this.invoiceData.items[0].itemTitle) {
        // shake alert quand le champ article n'est pas rempli
        this.$swal({
          title: "Ajouter un article",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__shakeX",
          },
          buttonsStyling: false,
        });
      } else {
        if (this.invoiceData.libelle.length > 30) {
          this.errorInput.path = "libelle_invoice";
          this.errorInput.message = "l'object 30 charatere maximun sont requis";
        } else if (this.invoiceData.remise === null && this.invoiceData.taxe === null) {
          const data = {
            client: this.factureClient.id,
            date_emission: this.dateDefault,
            date_echeance: this.dueDate,
            entreprise: this.factureClient.id,
            items: this.invoiceData.items,
            vendeur: this.invoiceData.vendeur,
            description: this.invoiceData.note,
            total_ttc: this.invoiceData.facturePrixTotal,
            total_ht: this.invoiceData.facturePrixTotalHt,
            nombre_article: this.invoiceData.items.length,
            articles_id: this.invoiceData.articles_id,
            type_facture: type,
            etat: "draft",
            libelle: this.invoiceData.libelle,
          };

          console.log(data, this.articlesList[0].id, this.invoiceData.articles_id[0]);

          await axios
            .post(URL.FACTURE_CREATE, data, config)
            .then(({ data }) => {
              console.log(data);
              const invoiceData__preview = data.facture;
              this.invoiceData.id = invoiceData__preview.id;
              this.invoiceData.code = invoiceData__preview.code;
              this.invoiceData.client = this.factureClient;
              this.invoiceData.date_emission = this.dateDefault;
              this.invoiceData.date_echeance = this.dueDate;
              this.invoiceData.entreprise = this.entrerpiseData;
              this.invoiceData.logoEntreprise = this.picture;
              this.invoiceData.libelle = invoiceData__preview.libelle;
              this.invoiceData.total_ttc = this.invoiceData.facturePrixTotal;
              this.invoiceData.taxes = [];
              this.invoiceData.articles = this.articlesList.filter((el) => {
                return el.id === this.invoiceData.articles_id[0];
              });
              this.invoiceData.articles[0]["pivot"] = {
                prix_vente: data.facture_created[0].articles[0].pivot.prix_vente,
                prix: data.facture_created[0].articles[0].pivot.prix,
                quantite: data.facture_created[0].articles[0].pivot.quantite,
              };
              this.invoiceData.etat = "draft";
              this.invoiceData.versements =
                this.invoiceData.versements === undefined
                  ? []
                  : this.invoiceData.versements;
              invoiceData__preview.versements = this.invoiceData.versements;

              this.invoiceData.amountPaid = paymentRealTime.zip__amountPaid(
                invoiceData__preview
              );
              this.invoiceData.status = invoiceData__preview.status;
              this.invoiceData.amountToPaid = paymentRealTime.zip__amountToPaid(
                invoiceData__preview
              );

              if (data) {
                console.log(this.invoiceData);
                localStorage.setItem("facture", JSON.stringify(this.invoiceData));
                type === "facture"
                  ? this.$router.push("/facture/view")
                  : this.$router.push("/devis/view");
              }
            })
            .catch((error) => {
              console.log("api error", error.messagae);
              this.$swal({
                title: "Erreur",
                text: "Oups une erreur c'est produite, veillez rééssayer",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "Rééssayer",
                cancelButtonText: "Annuler",
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-outline-primary ml-1",
                },
                buttonsStyling: false,
              });
            });
        } else if (this.invoiceData.remise !== null && this.invoiceData.taxe === null) {
          const data = {
            client: this.factureClient.id,
            date_emission: this.dateDefault,
            date_echeance: this.dueDate,
            entreprise: this.factureClient.id,
            items: this.invoiceData.items,
            vendeur: this.invoiceData.vendeur,
            description: this.invoiceData.note,
            total_ttc: this.invoiceData.facturePrixTotal,
            total_ht: this.invoiceData.facturePrixTotalHt,
            remise: this.invoiceData.remise,
            nombre_article: this.invoiceData.items.length,
            articles_id: this.invoiceData.articles_id,
            type_facture: "facture",
            etat: "draft",
            libelle: this.invoiceData.libelle,
          };

          await axios
            .post(URL.FACTURE_CREATE, data, config)
            .then(({ data }) => {
              const invoiceData__preview = data.facture;
              this.invoiceData.id = invoiceData__preview.id;
              this.invoiceData.code = invoiceData__preview.code;
              this.invoiceData.client = this.factureClient;
              this.invoiceData.date_emission = this.dateDefault;
              this.invoiceData.date_echeance = this.dueDate;
              this.invoiceData.entreprise = this.entrerpiseData;
              this.invoiceData.logoEntreprise = this.picture;
              this.invoiceData.libelle = invoiceData__preview.libelle;
              this.invoiceData.total_ttc = this.invoiceData.facturePrixTotal;
              this.invoiceData.taxes = [];
              this.invoiceData.articles = this.articlesList.filter((el) => {
                return el.id === this.invoiceData.articles_id[0];
              });
              this.invoiceData.status = invoiceData__preview.status;
              this.invoiceData.articles[0]["pivot"] = {
                prix_vente: data.facture_created[0].articles[0].pivot.prix_vente,
                prix: data.facture_created[0].articles[0].pivot.prix,
                quantite: data.facture_created[0].articles[0].pivot.quantite,
              };
              this.invoiceData.etat = "draft";
              this.invoiceData.versements =
                this.invoiceData.versements === undefined
                  ? []
                  : this.invoiceData.versements;
              invoiceData__preview.versements = this.invoiceData.versements;

              this.invoiceData.amountPaid = paymentRealTime.zip__amountPaid(
                invoiceData__preview
              );
              this.invoiceData.amountToPaid = paymentRealTime.zip__amountToPaid(
                invoiceData__preview
              );

              if (data) {
                localStorage.setItem("facture", JSON.stringify(this.invoiceData));
                type === "facture"
                  ? this.$router.push("/facture/view")
                  : this.$router.push("/devis/view");
              }
            })
            .catch((error) => {
              console.log("api error", error.messagae);
              this.$swal({
                title: "Erreur",
                text: "Votre facture n'est pas été mise en broullion, veillez rééssayer",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "Rééssayer",
                cancelButtonText: "Annuler",
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-outline-primary ml-1",
                },
                buttonsStyling: false,
              });
            });
        } else if (this.invoiceData.remise === null && this.invoiceData.taxe !== null) {
          const data = {
            client: this.factureClient.id,
            date_emission: this.dateDefault,
            date_echeance: this.dueDate,
            entreprise: this.factureClient.id,
            items: this.invoiceData.items,
            vendeur: this.invoiceData.vendeur,
            description: this.invoiceData.note,
            remise: this.invoiceData.remise,
            total_ttc: this.invoiceData.facturePrixTotal,
            total_ht: this.invoiceData.facturePrixTotalHt,
            total_taxe: this.invoiceData.taxe.valeur,
            nombre_article: this.invoiceData.items.length,
            articles_id: this.invoiceData.articles_id,
            taxes_id: this.invoiceData.taxe.id,
            type_facture: "facture",
            etat: "draft",
            libelle: this.invoiceData.libelle,
          };

          await axios
            .post(URL.FACTURE_CREATE, data, config)
            .then(({ data }) => {
              const invoiceData__preview = data.facture;
              this.invoiceData.id = invoiceData__preview.id;
              this.invoiceData.code = invoiceData__preview.code;
              this.invoiceData.client = this.factureClient;
              this.invoiceData.date_emission = this.dateDefault;
              this.invoiceData.date_echeance = this.dueDate;
              this.invoiceData.entreprise = this.entrerpiseData;
              this.invoiceData.logoEntreprise = this.picture;
              this.invoiceData.taxes = this.invoiceData.taxe;
              this.invoiceData.libelle = invoiceData__preview.libelle;
              this.invoiceData.versements =
                this.invoiceData.versements === undefined
                  ? []
                  : this.invoiceData.versements;
              this.invoiceData.status = invoiceData__preview.status;
              invoiceData__preview.versements = this.invoiceData.versements;
              this.invoiceData.total_ttc = this.invoiceData.facturePrixTotal;
              this.invoiceData.articles = this.articlesList.filter((el) => {
                return el.id === this.invoiceData.articles_id[0];
              });
              this.invoiceData.articles[0]["pivot"] = {
                prix_vente: data.facture_created[0].articles[0].pivot.prix_vente,
                prix: data.facture_created[0].articles[0].pivot.prix,
                quantite: data.facture_created[0].articles[0].pivot.quantite,
              };
              this.invoiceData.etat = "draft";
              this.invoiceData.versements =
                this.invoiceData.versements === undefined
                  ? []
                  : this.invoiceData.versements;
              invoiceData__preview.versements = this.invoiceData.versements;

              this.invoiceData.amountPaid = paymentRealTime.zip__amountPaid(
                invoiceData__preview
              );
              this.invoiceData.amountToPaid = paymentRealTime.zip__amountToPaid(
                invoiceData__preview
              );

              if (data) {
                localStorage.setItem("facture", JSON.stringify(this.invoiceData));
                type === "facture"
                  ? this.$router.push("/facture/view")
                  : this.$router.push("/devis/view");
              }
            })
            .catch((error) => {
              console.log("api error", error.messagae);
              this.$swal({
                title: "Erreur",
                text: "Votre facture n'est pas été mise en broullion, veillez rééssayer",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "Rééssayer",
                cancelButtonText: "Annuler",
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-outline-primary ml-1",
                },
                buttonsStyling: false,
              });
            });
        } else {
          const data = {
            client: this.factureClient.id,
            date_emission: this.dateDefault,
            date_echeance: this.dueDate,
            entreprise: this.factureClient.id,
            items: this.invoiceData.items,
            vendeur: this.invoiceData.vendeur,
            description: this.invoiceData.note,
            total_ttc: this.invoiceData.facturePrixTotal,
            total_ht: this.invoiceData.facturePrixTotalHt,
            total_taxe: this.invoiceData.taxe.valeur,
            remise: this.invoiceData.remise,
            nombre_article: this.invoiceData.items.length,
            articles_id: this.invoiceData.articles_id,
            taxes_id: this.invoiceData.taxe.id,
            type_facture: "facture",
            etat: "draft",
            libelle: this.invoiceData.libelle,
          };
          await axios
            .post(URL.FACTURE_CREATE, data, config)
            .then(({ data }) => {
              const invoiceData__preview = data.facture;
              this.invoiceData.id = invoiceData__preview.id;
              this.invoiceData.code = invoiceData__preview.code;
              this.invoiceData.client = this.factureClient;
              this.invoiceData.date_emission = this.dateDefault;
              this.invoiceData.date_echeance = this.dueDate;
              this.invoiceData.entreprise = this.entrerpiseData;
              this.invoiceData.logoEntreprise = this.picture;
              this.invoiceData.taxes = this.invoiceData.taxe;
              this.invoiceData.libelle = invoiceData__preview.libelle;
              this.invoiceData.versements =
                this.invoiceData.versements === undefined
                  ? []
                  : this.invoiceData.versements;
              invoiceData__preview.versements = this.invoiceData.versements;
              this.invoiceData.total_ttc = this.invoiceData.facturePrixTotal;
              this.invoiceData.articles = this.articlesList.filter((el) => {
                return el.id === this.invoiceData.articles_id[0];
              });
              this.invoiceData.articles[0]["pivot"] = {
                prix_vente: data.facture_created[0].articles[0].pivot.prix_vente,
                prix: data.facture_created[0].articles[0].pivot.prix,
                quantite: data.facture_created[0].articles[0].pivot.quantite,
              };
              this.invoiceData.status = invoiceData__preview.status;
              this.invoiceData.etat = "draft";
              this.invoiceData.versements =
                this.invoiceData.versements === undefined
                  ? []
                  : this.invoiceData.versements;
              invoiceData__preview.versements = this.invoiceData.versements;

              this.invoiceData.amountPaid = paymentRealTime.zip__amountPaid(
                invoiceData__preview
              );
              this.invoiceData.amountToPaid = paymentRealTime.zip__amountToPaid(
                invoiceData__preview
              );

              if (data) {
                localStorage.setItem("facture", JSON.stringify(this.invoiceData));
                type === "facture"
                  ? this.$router.push("/facture/view")
                  : this.$router.push("/devis/view");
              }
            })
            .catch((error) => {
              console.log("api error", error.messagae);
              this.$swal({
                title: "Erreur",
                text: "Votre facture n'est pas été mise en broullion, veillez rééssayer",
                icon: "error",
                showCancelButton: true,
                confirmButtonText: "Rééssayer",
                cancelButtonText: "Annuler",
                customClass: {
                  confirmButton: "btn btn-primary",
                  cancelButton: "btn btn-outline-primary ml-1",
                },
                buttonsStyling: false,
              });
            });
        }
      }
    },

    // *****
    // ****
    // FUNCTION POUR AJOUT DE CLIENT
    // ****
    // *****

    // Alert When added new article
    showAlert() {
      this.$swal({
        title: "Article ajouté avec succès",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    /*******
     * *
     * *
     * *
     * *
     * *
     * *
     * *
     * *
     * *
     *
     */
    async sendModel(config, mail, type, url, text, textok, oklink, textno) {
      if (this.invoiceData.libelle.length > 30) {
        this.errorInput.path = "libelle_invoice";
        this.errorInput.message = "l'object 30 charatere maximun sont requis";
      } else if (this.invoiceData.remise === null && this.invoiceData.taxe === null) {
        const data = {
          id: this.invoiceData.id,
          client_id: this.factureClient.id,
          entreprise_id: this.factureClient.id,
          client: this.factureClient.id,
          date_emission: this.dateDefault,
          date_echeance: this.dueDate,
          entreprise: this.factureClient.id,
          items: this.invoiceData.items,
          vendeur: this.invoiceData.vendeur,
          description: this.invoiceData.note,
          total_ttc: this.invoiceData.facturePrixTotal,
          total_ht: this.invoiceData.facturePrixTotalHt,
          nombre_article: this.invoiceData.items.length,
          articles_id: this.invoiceData.articles_id,
          type_facture: type,
          etat: type === "devis" ? "draft" : "validate",
          libelle: this.invoiceData.libelle,
        };

        await axios
          .post(url, data, config)
          .then((res) => {
            if (res.data) {
              this.confirmText(text, textok, oklink, textno);
              if (mail === true) {
                this.mailSender = true;
              }
            }
          })
          .catch((error) => {
            console.log("api error", error.messagae);
            this.errorText();
          });
      } else if (this.invoiceData.remise !== null && this.invoiceData.taxe === null) {
        const data = {
          id: this.invoiceData.id,
          client_id: this.factureClient.id,
          entreprise_id: this.factureClient.id,
          client: this.factureClient.id,
          date_emission: this.dateDefault,
          date_echeance: this.dueDate,
          entreprise: this.factureClient.id,
          items: this.invoiceData.items,
          vendeur: this.invoiceData.vendeur,
          description: this.invoiceData.note,
          total_ttc: this.invoiceData.facturePrixTotal,
          total_ht: this.invoiceData.facturePrixTotalHt,
          remise: this.invoiceData.remise,
          nombre_article: this.invoiceData.items.length,
          articles_id: this.invoiceData.articles_id,
          type_facture: type,
          etat: type === "devis" ? "draft" : "validate",
          libelle: this.invoiceData.libelle,
        };

        await axios
          .post(url, data, config)
          .then((res) => {
            if (res.data) {
              this.confirmText(text, textok, oklink, textno);
              if (mail === true) {
                this.mailSender = true;
              }
            }
          })
          .catch((error) => {
            console.log("api error", error.messagae);
            this.errorText();
          });
      } else if (this.invoiceData.remise === null && this.invoiceData.taxe !== null) {
        const data = {
          id: this.invoiceData.id,
          client_id: this.factureClient.id,
          entreprise_id: this.factureClient.id,
          client: this.factureClient.id,
          date_emission: this.dateDefault,
          date_echeance: this.dueDate,
          entreprise: this.factureClient.id,
          items: this.invoiceData.items,
          vendeur: this.invoiceData.vendeur,
          description: this.invoiceData.note,
          remise: this.invoiceData.remise,
          total_ttc: this.invoiceData.facturePrixTotal,
          total_ht: this.invoiceData.facturePrixTotalHt,
          total_taxe: this.invoiceData.taxe.valuer,
          nombre_article: this.invoiceData.items.length,
          articles_id: this.invoiceData.articles_id,
          taxes_id: this.invoiceData.taxe.id,
          type_facture: type,
          etat: type === "devis" ? "draft" : "validate",
          libelle: this.invoiceData.libelle,
        };

        await axios
          .post(url, data, config)
          .then((res) => {
            if (res.data) {
              this.confirmText(text, textok, oklink, textno);
              if (mail === true) {
                this.mailSender = true;
              }
            }
          })
          .catch((error) => {
            console.log("api error", error.messagae);
            this.errorText();
          });
      } else {
        const data = {
          id: this.invoiceData.id,
          client_id: this.factureClient.id,
          entreprise_id: this.factureClient.id,
          client: this.factureClient.id,
          date_emission: this.dateDefault,
          date_echeance: this.dueDate,
          entreprise: this.factureClient.id,
          items: this.invoiceData.items,
          vendeur: this.invoiceData.vendeur,
          description: this.invoiceData.note,
          total_ttc: this.invoiceData.facturePrixTotal,
          total_ht: this.invoiceData.facturePrixTotalHt,
          total_taxe: this.invoiceData.taxe.valeur,
          remise: this.invoiceData.remise,
          nombre_article: this.invoiceData.items.length,
          articles_id: this.invoiceData.articles_id,
          taxes_id: this.invoiceData.taxe.id,
          type_facture: type,
          etat: type === "devis" ? "draft" : "validate",
          libelle: this.invoiceData.libelle,
        };
        await axios
          .post(URL.FACTURE_CREATE, data, config)
          .then((res) => {
            if (res.data) {
              this.confirmText(text, textok, oklink, textno);
              if (mail === true) {
                this.mailSender = true;
              }
            }
          })
          .catch((error) => {
            console.log("api error", error.messagae);
            this.errorText();
          });
      }
    },
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";
    const INVOICE_PERMISSIONS = ref(false);
    const QUOTES_PERMISSIONS = ref(false);
    const INVOICE_PERMISSIONS_UPDATE = ref(false);
    const QUOTES_PERMISSIONS_UPDATE = ref(false);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    onBeforeMount(async () => {
      if (root.$route.path === "/create/facture") {
        const { isAccess } = await usePerm.__PERMISSION__("facture", "store");
        INVOICE_PERMISSIONS.value = isAccess;
      } else if (root.$route.path === "/create/devis") {
        const { isAccess } = await usePerm.__PERMISSION__("devis", "store");
        QUOTES_PERMISSIONS.value = isAccess;
      } else if (root.$route.path === "/edit/facture") {
        const { isAccess } = await usePerm.__PERMISSION__("facture", "edit");
        INVOICE_PERMISSIONS_UPDATE.value = isAccess;
      } else if (root.$route.path === "/edit/devis") {
        const { isAccess } = await usePerm.__PERMISSION__("devis", "edit");
        QUOTES_PERMISSIONS_UPDATE.value = isAccess;
      }
    });

    const clients = ref([]);
    const errorInput = reactive({
      path: "",
      message: "",
    });

    const itemFormBlankItem = {
      nom: null,
      cout: 0,
      taxe: 0,
      qte: 1,
      prix: 0,
    };

    const invoiceData = ref({
      id: "0000",
      client: null,
      entreprise: null,
      logoEntreprise: null,
      date_emission: null,
      date_echeance: null,
      status: null,

      // ? Set single Item in form for adding data
      items: [],
      articles_id: [],
      articles: [],
      taxe: null,
      remise: null,
      taxes_id: null,
      // totalTaxe: [],
      nombre_article: 0,

      vendeur: "",
      facturePrixTotal: 0,
      facturePrixTotalHt: 0,
      factureTotalTaxe: 0,
      note: "50% à la livraison, 50% à la commande.\nNous vous remercions.",
      paiement: null,
      libelle: "",
    });

    // LISTE DES INFOS DE L'ENTREPRISE
    const entrerpiseData = ref("");
    const entreprise_logo = ref("");

    const updateItemForm = (index, val) => {
      const { prix_vente, id } = val;
      invoiceData.value.items[index].cout = prix_vente;
      invoiceData.value.items[index].qte = 1;
      invoiceData.value.articles_id[index] = id;
    };

    const qAccessForbidden = () => {
      console.log("test");
      toast_orign(
        root,
        "Accès interdit",
        "AlertTriangleIcon",
        "Vous n'avez pas les permissions pour pouvez pas effectuer cette action, veillez contactez votre adminstrateur",
        "warning",
        "top-right"
      );
    };

    // ***
    // RECUPERE LA TAXE ET LA MODIFIE
    // **
    const updateItemTaxe = (index, val) => {
      const { valeur, id } = val;
      invoiceData.value.items[index].taxe = valeur;
      invoiceData.value.totalTaxe[index] = valeur;
      invoiceData.value.taxes_id[index] = valeur;
    };

    // *****
    // ***
    // VARIABLES DECLAREES PAR MOI - AIME
    // ***
    // *****
    // LISTE DES ARTICLES
    const articlesList = ref([]);
    // LISTE DES TAXES
    const taxesList = ref([]);
    // VARIABLES POUR UPLOAD LE LOGO
    const picture = ref("");
    const choose = ref("");
    // VARIABLES POUR LA LISTE DES CLIENTS
    const clientList = ref([]);
    const factureClient = ref(null);
    // VARIABLES POUR LES DATES
    const dateDefault = ref(moment(new Date().toString()).format("YYYY-MM-DD"));
    const dueDate = ref(
      moment(new Date().toString()).add(1, "month").format("YYYY-MM-DD")
    );
    const mailSender = ref(false);
    // VARIABLES POUR LES ARTICLES
    const articleBlank = ref({
      nom: null,
      cout: 0,
      taxe: 0,
      qte: 1,
      prix: 0,
    });

    const invoiceTaxe = ref(null);
    const invoiceRemise = ref(null);

    const date_alert = ref(null);

    const saved_facture = ref(null);

    const clientUpOption = ref([{ title: "Particulier" }, { title: "Entreprise" }]);

    const query = computed(() => {
      let link = root.$route.path;
      link = link.split("/")[2];
      console.log(link);

      if (link.toLowerCase() === "facture" && root.$route.path === "/create/facture") {
        return "invoice";
      } else if (
        link.toLowerCase() === "facture" &&
        root.$route.path === "/edit/facture"
      ) {
        return "eInvoice";
      } else if (link.toLowerCase() === "devis" && root.$route.path === "/create/devis") {
        return "devis";
      } else if (link.toLowerCase() === "devis" && root.$route.path === "/edit/devis") {
        return "eDevis";
      } else {
        location.assign("/error-404");
      }
    });

    const AllGive = () => {
      if (query.value === "eDevis" || query.value === "eInvoice") {
        const getData = JSON.parse(localStorage.getItem("edit_facture"));
        factureClient.value = getData.client;
        dateDefault.value = getData.date_emission;
        dueDate.value = getData.date_echeance;
        getData.taxe = getData.taxes.length === 0 ? null : getData.taxes[0];
        getData.articles_id = [];
        getData.articles.forEach((el, index) => {
          getData.articles_id.push(el.id);
        });
        invoiceData.value = getData;
      }
    };

    // onBeforeMount(() => {
    // 	// Create Invoice and Devis
    // 	if (
    // 		root.$route.params.type === 'facture' &&
    // 		root.$route.path === '/create/f/facture'
    // 	) {
    // 		query.value = 'invoice';
    // 	} else if (
    // 		root.$route.params.type === 'devis' &&
    // 		root.$route.path === '/create/d/devis'
    // 	) {
    // 		query.value = 'devis';
    // 	}
    // 	// Edit Invoice and Devis
    // 	else if (
    // 		root.$route.params.type === 'devis' &&
    // 		root.$route.path === '/edit/d/devis'
    // 	) {
    // 		query.value = 'eDevis';
    // 		const getData = JSON.parse(localStorage.getItem('edit_facture'));
    // 		factureClient.value = getData.client;
    // 		dateDefault.value = getData.date_emission;
    // 		dueDate.value = getData.date_echeance;
    // 		getData.taxe = getData.taxes.length === 0 ? null : getData.taxes;
    // 		getData.articles_id = [];
    // 		getData.articles.forEach((el, index) => {
    // 			getData.articles_id.push(el.id);
    // 		});
    // 		invoiceData.value = getData;
    // 	} else if (
    // 		root.$route.params.type === 'facture' &&
    // 		root.$route.path === '/edit/f/facture'
    // 	) {
    // 		query.value = 'eInvoice';
    // 		const getData = JSON.parse(localStorage.getItem('edit_facture'));
    // 		factureClient.value = getData.client;
    // 		dateDefault.value = getData.date_emission;
    // 		dueDate.value = getData.date_echeance;
    // 		getData.taxe = getData.taxes.length === 0 ? null : getData.taxes;
    // 		getData.articles_id = [];
    // 		getData.articles.forEach((el, index) => {
    // 			getData.articles_id.push(el.id);
    // 		});
    // 		invoiceData.value = getData;
    // 	}
    // 	// In case of error
    // 	else {
    // 		location.assign('/error-404');
    // 	}
    // });

    // FUNCTION TO CONVERT NUMBER INTO CURRENCY
    // Formater Money
    const formatter = (amount) => {
      const dataToDecrypt = localStorage.getItem("entreprise_info");
      return qDeviseUtils.formatter(qDecrypted(dataToDecrypt), amount);
    };

    const contactEntier = (e) => {
      newUser.value.contact = e;
    };

    const changer = (e) => {
      if (e.name) {
        newUser.value.contact.country.name = e.name;
      }
    };

    const processFile2 = (event) => {
      newArticle.value.file = event.target.files[0];
      if (event.target.files.length !== 0) {
      }
    };

    // NEW USER DETAIL
    const newUser = ref({
      nom: "",
      prenoms: "",
      email: "",
      contact: "",
      localisation: "",
      statut: "",
    });

    // DATA FOR NEW USER CREATION
    const phone = ref("");
    const indicatifFinal = ref("");
    const status_id = ref(null);

    // NEW ARTICLE DETAILS
    const newArticle = ref({
      libelle: "",
      categorie: "",
      type: "",
      poids: "",
      unite: "",
      prix_achat: "",
      prix_vente: "",
      movie: "",
      file: null,
      description: "",
    });

    // DATA FOR NEW ARTICLE CREATION
    const optionUnite = ref(["Kg", "Unité", "Litre"]);
    const optionType = ref(["Service", "Produit"]);
    const categories = ref([]);

    return {
      //
      INVOICE_PERMISSIONS,
      qAccessForbidden,

      //
      invoiceData,
      clients,
      updateItemForm,
      itemFormBlankItem,
      invoiceTaxe,
      invoiceRemise,
      entreprise_logo,
      errorInput,
      mailSender,
      // ***
      // VARIABLE QUE JAI AJOUTE
      // ***
      categories,
      processFile2,
      optionType,
      optionUnite,
      newArticle,
      status_id,
      indicatifFinal,
      phone,
      newUser,
      contactEntier,
      changer,
      clientUpOption,
      entrerpiseData,
      picture,
      choose,
      articleBlank,
      dateDefault,
      dueDate,
      clientList,
      factureClient,
      updateItemTaxe,
      articlesList,
      taxesList,
      date_alert,
      saved_facture,
      formatter,
      query,
      AllGive,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.invoice-total-wrapper {
  max-width: 15rem !important;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.vti__dropdown-list {
  z-index: 1000;
}

.invoice-total-wrapper {
  max-width: 65% !important;
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

// div.sticky {
//   position: -webkit-sticky;
//   position: sticky;
//   top: 0;
//   background-color: yellow;
//   padding: 50px;
//   font-size: 20px;
// }

.upload-logo {
  position: relative;
  // border: 1px solid #6e6b7b;
  width: 100px;
  height: 100px;
  // border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.upload-logo .upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-logo .upload .upload-icon {
  width: 80%;
  height: 40px;
  margin: 15px auto 0;
}

.upload-logo .upload p {
  text-align: center;
  color: #6e6b7b;
  font-weight: 600;
}

.upload-logo #logo-img {
  width: 100%;
  height: 100%;
  // border-radius: 10px;
  object-fit: cover;
  display: block;
}

.upload-logo #logo-img.visible {
  display: block;
}

.upload-logo #logo-img.invisible {
  display: none;
}

.upload-logo button {
  position: absolute;
  top: 0;
  right: 0;
  outline: none;
  background-color: #450077;
  color: white;
  border-radius: 8px;
  border: none !important;
  font-size: 12px;
  padding: 5px 10px;
}
[dir] button::-moz-focus-inner,
[dir] [type="button"]::-moz-focus-inner,
[dir] [type="reset"]::-moz-focus-inner,
[dir] [type="submit"]::-moz-focus-inner {
  padding-left: 0;
  padding-right: 0;
  border-style: none;
}
</style>
